<template>
    <v-container>
        <v-row justify="center" class="pt-5">
            <!-- TODO: realm name and logo? -->
            <!-- <h1 v-show="loginUsernameInput" class="text-h4 font-weight-light">Login</h1>
            <h1 v-show="loginWithLoginShield && !isActivatingLoginShield" class="text-h4 font-weight-light">Login</h1>
            <h1 v-show="loginWithLoginShield && isActivatingLoginShield" class="text-h4 font-weight-light">Account</h1> -->
            <!-- <h1 class="text-h4 font-weight-light" v-show="isActivatingLoginShield">Account</h1>
            <h1 class="text-h4 font-weight-light" v-show="!isActivatingLoginShield">Login</h1> -->
        </v-row>
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <v-card elevation="4" class="px-10 py-5 pt-10 mt-5">
                    <p v-if="!loginshieldStartError">Please wait...</p>
                    <p v-if="loginshieldStartError" class="red--text">Service unavailable. Please try again later.</p>
                    <!-- <v-form @submit="loginUsername" onSubmit="return false;">
                        <v-text-field
                            v-model=username
                            label="Email Address"
                            color="teal"
                            autofocus
                            outlined
                            ref="usernameField"
                        ></v-text-field>
                        <v-card-actions>
                            <v-row justify="center">
                                <v-btn elevation="4" class="teal white--text" @click="loginUsername" :disabled="!isUsernameFormComplete">
                                    Next
                                </v-btn>
                            </v-row>
                        </v-card-actions>
                    </v-form> -->
                </v-card>
                <!-- TODO: for LoginFront iframe... -->
                <!-- <v-row justify="center" v-show="loginWithLoginShield" class="ma-0 pt-5" style="width: 100%;">
                    <div id="loginshield-content" style="width: 100%; height: 600px;"></div>
                </v-row> -->
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
// import { loginshieldInit } from '@loginshield/realm-client-browser';
// import { client } from '@/client';
// function loginshieldInit() {
//     return null; // TODO: we should make a loginfront sdk with iframe like loginshield, so it can be used without redirecting user to another site
// }

// TODO: use loginfront sdk to login, instead of having it built in here

export default {
    data() {
        return {
            from: null,
            isActivatingLoginShield: false,
            loginUsernameInput: true,
            loginPasswordInput: false,
            loginWithLoginShield: false,
            username: null,
            password: null,
            passwordError: false,
            loginshieldStartError: false,
        };
    },

    watch: {
        isReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
    },

    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
            account: (state) => state.account,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },

    methods: {
        async init() {
            try {
                this.$store.commit('loading', { startLogin: true });
                if (this.$route.query.loginfront) {
                    const { isAuthenticated } = await this.$client.main().authn.checkLogin({ token: this.$route.query.loginfront });
                    await this.updateSession();
                    if (isAuthenticated) {
                        this.redirectAfterLogin();
                        return;
                    }
                }

                // if another view redirected here and already knows the username, we can pre-fill
                // the username in the login form; the user can still change it at LoginFront
                const request = {
                    email: this.$route.query.email,
                };
                const { redirect } = await this.$client.main().authn.startLogin(request);
                console.log(`Login.vue init redirect: ${redirect}`);
                if (redirect) {
                    // use replace so that when user taps 'back' button from there, they won't
                    // end up being redirected again to where they just wanted to come back from
                    if (typeof window.location.replace === 'function') {
                        window.location.replace(redirect);
                    } else {
                        // TODO: also show link for user to click
                        window.location.href = redirect;
                    }
                }
            } catch (err) {
                console.error('start login failed', err);
                this.loginshieldStartError = true;
            } finally {
                this.$store.commit('loading', { startLogin: false });
            }
        },
        async updateSession() {
            await this.$store.dispatch('loadSession');
            if (this.session.isAuthenticated) {
                await this.$store.dispatch('loadUser');
            } else {
                this.$store.commit('setUser', {});
            }
        },
        async redirectAfterLogin({ nextInteractionId } = {}) {
            if (nextInteractionId) {
                const nextInteraction = await this.$store.dispatch('loadInteraction', nextInteractionId);
                console.log('finishLoginShield: next interaction: %o', nextInteraction);
                if (nextInteraction && nextInteraction.type) {
                    switch (nextInteraction.type) {
                    case 'require_login':
                        this.$router.push(nextInteraction.state.redirect);
                        return;
                    default:
                        this.$router.push({ path: '/dashboard', query: { i: nextInteractionId } });
                        return;
                    }
                }
            }
            if (this.next) {
                this.$router.push(this.next);
                return;
            }
            this.$router.push('/dashboard');
        },
    },

    mounted() {
        // if another view redirected here as part of a larger interaction like creating
        // an account, there should be an interaction id in the query
        this.interactionId = this.$route.query.i;

        // if another view redirected here with the intent of returning, it has to provide
        // the path and optional query parameters to redirect the user after login; the path
        // MUST be a relative path because this is not the right place for unrelated logic
        // about where we can redirect the user; the 'next' route could then redirect the
        // user to an offsite URL if needed
        const { next } = this.$route.query;
        if (typeof next === 'string' && next.startsWith('/')) {
            this.next = next;
        }
        console.log('Login.vue: mounted with isReady: %o and isAuthenticated: %o', this.isReady, this.isAuthenticated);
        if (this.isReady) {
            this.init();
        }
    },
};
</script>
